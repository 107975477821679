<template>
    <div>
        <div class="filter-wrap flex-between">
<!--            <SearchFilter @handleFilter="handleFilter" :SearchStr.sync="listQuery.SearchStr" />-->
            <div class="flex-start">
                <el-time-picker
                        class="mr10"
                        is-range
                        format="HH:mm"
                        value-format="HH:mm"
                        v-model="time"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                </el-time-picker>
                <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleFilter"
                >
                    搜索
                </el-button>
            </div>
            <el-button @click="back">
                返 回
            </el-button>
        </div>
        <el-table
                v-loading="loading"
                fit
                border
                highlight-current-row
                :data="tableData"
                stripe
                style="width: 100%"
        >
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="fieldName"
                    label="字段名称"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="assetsIp"
                    label="IT资产"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="saveDatabase"
                    label="库名"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="saveTable"
                    label="表名"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="saveTableField"
                    label="表字段名称"
            />
            <el-table-column
                    :show-saveCount-tooltip="true"
                    prop="saveCount"
                    label="数据规模"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="createTime"
                    label="发现时间"
            />
            <el-table-column
                    label="操作"
                    width="80px"
            >
                <template slot-scope="{row}">
                    <el-link
                            :underline="false"
                            class="mr10"
                            type="primary"
                    >
                        <el-tooltip
                                content="入库"
                                placement="top"
                                effect="light"
                        >
                            <i class="icon-ruku iconfont" />
                        </el-tooltip>
                    </el-link>
                    <el-link
                            type="primary"
                            @click="del(row)"
                    >
                        <el-tooltip
                                content="删除"
                                placement="top"
                                effect="light"
                        >
                            <i class="el-icon-delete" />
                        </el-tooltip>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.pageSize"
                @pagination="getList"
        />
    </div>
</template>

<script>
  import pagination from '@core/components/pagination/Pagination.vue'
  import { DeleteLabelById, GetLabelPage } from '@/api/system/privacy/privacy'
  import { GetDataFindResultInfoList,DeleteDataFindResultByIds } from '@/api/dataFound/dataFound'
  import { error, findBtnPerm, success } from '@core/utils/utils'

  export default {
    components: {
      pagination,
    },

    data() {
      return {
        time: '',
        savePerm: findBtnPerm('42'),
        updatePerm: findBtnPerm('43'),
        delPerm: findBtnPerm('44'),
        detailShow: false,
        loading: false,
        total: 0,
        listQuery: {
          page: 1,
          pageSize: 10,
          SearchStr: '',
          dataFindId: Number(this.$route.query.id),
          syncBeginTime: '',
          syncEndTime: '',
        },
        tableData: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      back() {
        sessionStorage.setItem('activeName', '2')
        this.$router.push('/infoAssest')
      },
      del(row) {
        this.$confirm('是否确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          const ids = []
          ids.push(row.id)
          DeleteDataFindResultByIds({ ids: ids }).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
        })
      },
      getList() {
        this.loading = true
        if(this.time) {
          this.listQuery.syncBeginTime = this.time[0]
          this.listQuery.syncEndTime = this.time[1]
        }else{
          this.listQuery.syncBeginTime = ''
          this.listQuery.syncEndTime = ''
        }
        GetDataFindResultInfoList(this.listQuery).then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        }).catch(() => {
          this.loading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    },
    mounted() {
      this.navActiveInit(1,'个人信息资产', '数据智能')
    },
    destroyed() {
      this.navActiveInit(2, '个人信息资产', '数据智能', '/infoAssest')
    },

  }
</script>

<style scoped>

</style>
